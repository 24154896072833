import React from "react"
import styles from "../../../styles/notFound.module.scss"
import Layout from "../../Layout/Layout"
import tt from '../../../helpers/translation'


const NotFound = ({ lang, urls, urlImageNotFound }) => {
  return (
    <Layout
      lan={lang}
      location="not-found"
      contrast={true}
      urls={urls}
      noFooter
    >
      <div className={styles.container}>
        <img src={urlImageNotFound} alt='' />
        <h3>{tt("OOPS! SOMETHING WENT WRONG", lang)}</h3>
        <p>{tt("We have not found the page you have requested. In the main menu you can browse the categories of our website. Thank you!", lang)}</p>
      </div>
    </Layout>
  )
}

export default NotFound